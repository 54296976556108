@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --beerOrange: #f3b232;
  --beerLightOrange: #face78;
  --beerDarkOrange: #FE8E21;
  --grad-orng-1: #ffcd90;
  --grad-orng-2: #ffb76b;
  --grad-orng-3: #ffa652;
  --grad-orng-4: #ff8d21;
  --grad-orng-5: #ff7b00;
}

@layer base{
  html{
    @apply min-h-full;
  }
}

@layer components{
  .col-flex-center{
    @apply flex flex-col items-center;
  }

  .row-flex-center{
    @apply flex flex-row justify-center;
  }

  .nice-trans{
    @apply transition-all duration-300 ease-in-out;
  }

  .drinks-card-mobile{
    @apply drinks-card col-flex-center w-3/4 relative;
  }

  .drinks-card-responsive{
    @apply sm:w-2/5;
    @apply md:w-1/5;
    @apply lg:w-1/6;
    @apply lg:min-h-80;
  }

  .drink-card-desktop{
    @apply drinks-card col-flex-center justify-center w-3/4 relative sm:w-2/5 md:w-1/5 lg:w-1/6;
  }

  .drinks-card-mobile, .drinks-card-desktop{
    @apply font-semibold;
  }

  .flippable-card-back{
    @apply nice-trans absolute size-full col-flex-center text-clip text-center opacity-0;
  }

  .flippable-card-front{
    @apply nice-trans size-full col-flex-center justify-between text-clip text-center;
  }

  .random-pick-card{
    @apply text-lg;
    @apply md:text-xl;
    @apply lg:text-2xl;
  }
  
  .random-pick-card .drink-header {
    @apply w-full;
  }

  .random-pick-card .drink-img-container img{
    @apply max-h-96;
  }

  .drink-link{
    @apply p-2 rounded-md bg-beerOrange text-white font-semibold;
    @apply transition-colors duration-300 ease-in-out;
    @apply hover:bg-beerLightOrange;
  }

  .text-one-line{
    @apply whitespace-nowrap overflow-ellipsis overflow-hidden;
  }

  .card-btn{
    @apply rounded-md p-2 bg-white;
  }

  .hover-beer{
    @apply nice-trans hover:text-beerOrange;
  }

  .page-nav-btn{
    @apply card-btn hover-beer;
  }

  .disabled-nav-btn{
    @apply card-btn text-gray-500;
  }

  .max-max{
    @apply !max-h-max !max-w-max;
  }

  .hide{
    @apply h-0 max-h-0 opacity-0;
  }

  .card{
    @apply border-shadow p-3 col-flex-center justify-between w-full aspect-square bg-beerOrange text-white;
    @apply max-w-[400px];
    @apply largeMobile:max-w-60 largeMobile:w-60;
    @apply md:max-w-72 md:w-72;
    @apply lg:max-w-96 lg:w-96;
  }

  .about-card{
    @apply w-full h-full p-4 border-shadow lg:aspect-square bg-beerDarkOrange col-flex-center justify-between;
    @apply first:flex-grow;
  }

  .about-card p{
    @apply text-center;
    @apply first:flex-grow first:flex first:items-center first:text-left first:md:text-center;
    @apply last:text-lg last:font-bold last:w-full last:p-2 last:mt-3 last:bg-beerOrange;
  }

  .card .title{
    @apply text-xl font-semibold text-center flex-grow flex items-center;
  }
  
  .card .timeline{
    @apply text-lg font-bold text-center w-full p-2 bg-beerLightOrange;
  }
}

.slide-pane__header{
  @apply !bg-appleGray;
}

.slide-pane__close{
  @apply !bg-appleLightGray rounded-sm;
}

.slide-pane__title-wrapper{
  @apply text-white;
}

.slide-pane__content{
  @apply !bg-microsoftGray;
}

.drink-fill-grad{
  @apply bg-clip-text text-transparent;
  background-image: linear-gradient(to top, var(--beerOrange), #f0be5b, var(--beerLightOrange));
}

.drinkDetails th{
  @apply pl-2 text-left font-medium;
}

.drinkDetails tr{
  @apply odd:bg-orange-100;
}

.drinkDetails td{
  @apply text-left;
} 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
.App{
  background-image: linear-gradient(to bottom, rgb(240, 213, 181), rgb(241, 180, 109), rgb(226, 144, 50), rgb(241, 180, 109), rgb(240, 213, 181));
} */

.drinks-card{
  @apply !m-0;
  transform-style: preserve-3d;
  transition: all 250ms ease-in-out;
}

.drinks-card h2, .drinks-card h3{
  @apply font-semibold;
  font-family: "Hind", sans-serif;
}

.flip{
  filter: blur(10px);
}

.lobster{
  font-family: "Lobster", sans-serif;
}

header h1{
  font-family: "Lobster", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.hind{
  font-family: "Hind", sans-serif;
}

.moul{
  font-family: "Moul", serif;
}

.container{
  @apply rounded-lg;
  box-shadow: 1px 1px 8px rgba(0, 0, 10, 0.497);
}

.border-shadow{
  box-shadow: 1px 1px 8px rgba(0, 0, 10, 0.497);
}

body{
  @apply bg-orange-200; 
}


.drinks-entry-container{
  @apply col-flex-center p-8 lg:p-12 bg-beerOrange overflow-hidden;
  /* background-image: linear-gradient(var(--grad-orng-1), var(--grad-orng-3), var(--grad-orng-4), var(--grad-orng-5), var(--grad-orng-1)); */
}

.mission-statement{
  @apply p-5;
}

.drinks-entry-btn{
  @apply rounded-lg outline-none opacity-100;
  border: 5em;
  -webkit-transform: translate(0);
  transform: translate(0);
  background-image: linear-gradient(45deg, #4568dc, #b06ab3);
  padding: 0.7em 2em;
  box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438);
  -webkit-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, opacity 300ms;
}

.drinks-entry-btn:hover{
  opacity: 90%;
}